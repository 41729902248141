import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePassword, ReturnState } from '../../datamodels';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  changePwModel: ChangePassword = {
    currentPassword: '',
    newPassword: '',
    retypedPassword: ''
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private snackBar: MatSnackBar)
  {
  };

  changePassword()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/security/changepassword', this.changePwModel)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Password changed.', 'Close');
            this.changePwModel = {
              currentPassword: '',
              newPassword: '',
              retypedPassword: ''
            };
          }
          else if (result.stateID == 0)
          {
            this.snackBarRef = this.snackBar.open('Password and retyped password are different!', 'Close');
          }
          else if (result.stateID == 1)
          {
            this.snackBarRef = this.snackBar.open('The password is empty!', 'Close');
          }
          else if (result.stateID == 2)
          {
            this.snackBarRef = this.snackBar.open('The password is not correct!', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };
}
