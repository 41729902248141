import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language, LanguageToDeviceAssignment, ReturnState, Global } from './../../datamodels.d'
import { HistoryComponent } from '../history/history.component';
import { MatDialogRef, MatDialog, MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { babel } from '../../globals';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-languages-assignment',
  templateUrl: './languages-assignment.component.html',
  styleUrls: ['./languages-assignment.component.css']
})

export class LanguagesAssignmentComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  babel: Global = babel;

  public languages: LanguageToDeviceAssignment[];
  public cultures: string[];

  public taskID: number = 0;
  public taskName: string = '';
  public deviceID: number = 0;
  public deviceName: string = '';

  public onSaved = new EventEmitter();
  public onUpdated = new EventEmitter();

  dialogGeneric: MatDialogRef<DialogComponent>;

  displayedColumns: string[] = ['selected', 'name', 'originalCulture', 'culture', 'fileName', 'rightToLeft', 'isDefault', 'imported'];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    this.getLanguages();
    this.getAllCultureCodes();
  };

  getAllCultureCodes()
  {
    this.http.get<string[]>(this.baseUrl + 'api/languages/cultures/all').subscribe(result =>
    {
      this.cultures = result;
    }, error => console.error(error));
  };

  getLanguages()
  {
    this.http.get<LanguageToDeviceAssignment[]>(this.baseUrl + 'api/languages/translationtask/' + this.taskID + '/assignable').subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));
  };

  remove(_assignment: LanguageToDeviceAssignment)
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = 'Warning';
    this.dialogGeneric.componentInstance.options.message = 'Do you want to delete this language?';
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }
      this.http
        .delete<ReturnState>(this.baseUrl + 'api/texts/translationtask/' + this.taskID + '/language/' + _assignment.languageID, {})
        .subscribe(
          _result =>
          {
            var result = _result as ReturnState;
            if (result.success)
            {
              this.snackBarRef = this.snackBar.open('Language removed.', 'Close');
              this.getLanguages();
            }
            else if (result.stateID == 1) // Invalid job-state
            {
              this.snackBarRef = this.snackBar.open('At least one state is not in state [DRAFT_NEW]!', 'Close');
            }

            this.onUpdated.emit();
          },
          error => console.error(error));
    });
  };

  save()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/languages/translationtask/' + this.taskID, this.languages)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Languages saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }

          this.onSaved.emit();
        },
        error => console.error(error));
  };

  close()
  {
    this.onSaved.emit();
  };

  removeLanguage(_language: LanguageToDeviceAssignment)
  {
    var idx = this.languages.indexOf(_language)
    if (idx >= 0)
      this.languages.splice(0, 1);
  };

  addLanguage()
  {
    var tmp = [];
    tmp.push({
      id: 0,
      name: '',
      culture: '',
      deviceID: this.deviceID,
      languageID: 0,
      languageName: '',
      languageCulture: '',
      selected: true,
      isRightToLeft: false,
      imported: false
    });
    tmp = tmp.concat(this.languages);
    this.languages = tmp;
  };
}
