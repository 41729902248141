import { UserExtended } from './datamodels';

export class UserExtendedModel implements UserExtended
{
  id: number;
  name: string;
  assigned: boolean;
  eMail: string;
  inExternal: number;
  //password?: string;
  //companyID?: number;
  companyDisplayName: string;
  roles: [];
  roleIDs: [];
  disabled: boolean;
}
