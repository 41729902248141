import { TextExtended } from './datamodels';

export class TextExtendedModel implements TextExtended
{
  id: number = 0;

  toTranslate: boolean = true;

  languageID: number = 0;
  languageDisplayName: string = '';

  originalLanguageID: number = 0;
  originalLanguageDisplayName: string = '';

  stateID: number = 0;
  stateDisplayName: string = '';

  textID: number = 0;
  text: string = '';
  textOriginal: string = '';

  ignoreTextAreaSize: boolean = false;
  textTypeID: number = null;
  textTypeName: string = '';
  
  name: string = '';
  createDate: Date = new Date();

  onlineTranslated: boolean = false;
}
