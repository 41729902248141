import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogOptions } from '../../datamodels';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogComponent
{
  public options: DialogOptions = {
    title: 'empty title',
    message: 'empty message',
    actionCancel: false,
    actionNo: false,
    actionOk: false,
    actionYes: false
  };

  public onAction = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  {
  };

  ngOnInit()
  {
  };

  handleAction(_action: string)
  {
    this.dialogRef.close({
      action: _action
    });

    this.onAction.emit({
      action: _action
    });
  };

}
