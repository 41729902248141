import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserExtended, ReturnState } from './../../datamodels.d'
import { HistoryComponent } from '../history/history.component';
import { MatDialogRef, MatDialog, MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SecurityService } from '../security.service';
import { babel } from '../../globals';
import { Global } from '../../datamodels';

@Component({
  selector: 'app-users-summary',
  templateUrl: './users-summary.component.html',
  styleUrls: ['./users-summary.component.css']
})

export class UsersSummaryComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  dialogRef: MatDialogRef<HistoryComponent>;
  public users: UserExtended[];

  companyCount: number;

  babel: Global = babel;

  displayedColumns: string[] = ['eMail', 'name', 'roles', 'inExternal', 'companyDisplayName', 'disabled', 'loginAttempts', 'edit'];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService)
  {
    this.security.checkForRolesByName('users.summary');

    this.getUsers();
    this.getCompanyCount();
  };

  openHistory(_userID: number)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.userID = _userID;
  };

  getUsers()
  {

    this.http.get<UserExtended[]>(this.baseUrl + 'api/users/all').subscribe(result =>
    {
      this.users = result;
    }, error => console.error(error));
  };

  resetLoginAttempts(_userID: number)
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/users/resetloginattempts/' + _userID, {})
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Login attempts resetted.', 'Close');
            this.getUsers();
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };

  getCompanyCount()
  {
    this.http
      .get<ReturnState>(this.baseUrl + 'api/companies/totalcount/')
      .subscribe(result =>
      {
        if (!result.success)
          return;

        this.companyCount = result.data as unknown as number;

      }, error => console.error(error));
  };

  newUser()
  {
    if (this.companyCount === 0)
    {
      this.snackBarRef = this.snackBar.open('At least one company is required for adding a user. You need to add one first.', 'Close');
      return;
    }

    this.router.navigate(['/users/new']);
  };
}
