import { Company } from './datamodels';

export class CompanyModel implements Company
{
  id: number;
  name: string;
  assigned: boolean;
  translationJobID: number;
  disabled: boolean;
}
