//
// ===== File globals.ts    
//
'use strict';

import { Global, RoleAccessObjects } from './datamodels';

export let babel: Global = {
  ADMIN: 'A',
  DEV: 'D',
  TASK_CREATOR: 'TC',// TECHNICAL_WRITER
  TRANSLATOR: 'T',

  version: '0.0.0.0',
  user: '<no user>',

  roleAccessObjects: [],

  technicalRoleNames: null,

  setRoleAccessObjects(_rao: RoleAccessObjects[])
  {
    this.roleAccessObjects = _rao;
  },

  hasRole(_techRoleName: string): boolean
  {
    var has = this.technicalRoleNames !== null && this.technicalRoleNames.indexOf(_techRoleName) >= 0;
    return has;
  },

  missingRole(_techRoleName: string): boolean
  {
    var missing = !this.hasRole(_techRoleName);
    return missing;
  }
};
