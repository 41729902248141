import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Device, Task, Job, Language, ReturnState, JobExtended, JobCreation } from './../../datamodels.d'
import { ActivatedRoute, Router } from '@angular/router';
import { FileSystemFileEntry, UploadEvent, FileSystemDirectoryEntry, UploadFile } from 'ngx-file-drop';
import { SimpleSnackBar, MatSnackBarRef, MatSnackBar } from '@angular/material';
import { JobCreationModel } from '../../jobcreationmodel';

@Component({
  selector: 'app-translation-job-new',
  templateUrl: './translation-job-new.component.html',
  styleUrls: ['./translation-job-new.component.css']
})

export class TranslationJobNewComponent
{
  public files: UploadFile[] = [];

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  deviceID: number = 0;
  translationTaskID: number = 0;

  public languages: Language[];
  public devices: Device[] = [];
  jobs: JobCreation[] = [];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar)
  {
  }

  ngOnInit()
  {
    this.deviceID = parseInt(this.route.snapshot.paramMap.get('iddevice'));
    this.translationTaskID = parseInt(this.route.snapshot.paramMap.get('idtask'));

    this.getLanguages();
    this.getDetails();
  };

  getLanguages()
  {
    this.http.get<Language[]>(this.baseUrl + 'api/languages/translationtask/' + this.translationTaskID).subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));
  };

  getDetails()
  {
    this.http.get<Device[]>(this.baseUrl + 'api/devices/translationtask/' + this.translationTaskID).subscribe(result =>
    {
      this.devices = result;
    }, error => console.error(error));
  };

  save()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/translationjobs/device/' + this.deviceID + '/task/' + this.translationTaskID, this.jobs)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Translation jobs saved.', 'Close');
            this.router.navigate(['/']);
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };

  trackDeviceBy(_index: number, _device: Device)
  {
    return 'device' + _device.id.toString();
  };

  trackTaskBy(_index: number, _task: Task)
  {
    return 'task' + _task.id.toString();
  };

  trackJobBy(_index: number, _job: Job)
  {
    return 'job' + (_job.id ? _job.id : 0).toString();
  };

  addJob(_task: Task)
  {
    var job: JobCreation = new JobCreationModel();
    _task.translationJobs.push(job);
    this.jobs.push(job);
  };

  removeJob(_task: Task, _job: JobCreation)
  {
    var idx = _task.translationJobs.indexOf(_job);
    if (idx >= 0)
      _task.translationJobs.splice(idx, 1);

    var idx = this.jobs.indexOf(_job);
    if (idx >= 0)
      this.jobs.splice(idx, 1);
  };

  //public dropped(_event: UploadEvent, _device: Device, _task: Task, _job: JobCreation)
  //{
  //  this.files = _event.files;
  //  for (const droppedFile of _event.files)
  //  {
  //    // Is it a file?
  //    if (droppedFile.fileEntry.isFile)
  //    {
  //      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //      fileEntry.file((file: File) =>
  //      {
  //        const formData = new FormData();
  //        formData.append('_file', file, droppedFile.relativePath);
  //        this.http.post(this.baseUrl + 'api/translationjobs/analyze/device/' + this.deviceID, formData)
  //          .subscribe(data =>
  //          {
  //            var state = data as ReturnState;
  //            if (state.success)
  //            {
  //              var job = state.data as Job;
  //              var tmp: JobCreation;

  //              if (_job)
  //              {
  //                tmp = _job;
  //                //tmp.id = 0;
  //                //tmp.name = 'new';
  //                //tmp.deadline = new Date();
  //                //tmp.device = '';
  //                //tmp.versionDisplayName = '';
  //                //tmp.versionLatestDisplayName = '';
  //                //tmp.status = '';
  //                //tmp.statusDisplayName = '';
  //                //tmp.screenCount = 5;
  //                //tmp.languageDisplayName = '';
  //                //tmp.language = '';
  //                tmp.fileName = droppedFile.relativePath;
  //                tmp.languageID = job.languageID;
  //                tmp.versionID = job.versionID;
  //                //tmp.stateID = 0;
  //                tmp.translationTaskID = _task.id;
  //              }
  //              else
  //              {
  //                tmp = {
  //                  id: 0,
  //                  name: 'new',
  //                  deadline: new Date(),
  //                  device: '',
  //                  versionDisplayName: '',
  //                  versionLatestDisplayName: '',
  //                  status: '',
  //                  statusDisplayName: '',
  //                  screenCount: 5,
  //                  languageDisplayName: '',
  //                  language: '',

  //                  fileName: droppedFile.relativePath,
  //                  fileXml: '',

  //                  languageID: job.languageID,
  //                  versionID: job.versionID,
  //                  stateID: 0,
  //                  translationTaskID: _task.id
  //                };
  //                _task.translationJobs.push(tmp);
  //                this.jobs.push(tmp);
  //              }

  //              // Read file as xml.
  //              var fileReader = new FileReader();
  //              fileReader.onload = (_e) =>
  //              {
  //                var readXml = fileReader.result.toString();
  //                tmp.fileXml = readXml;
  //              };
  //              fileReader.readAsText(file);
  //            }
  //          })
  //      });
  //    }
  //    else
  //    {
  //      // It was a directory (empty directories are added, otherwise only files)
  //      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
  //      console.log(droppedFile.relativePath, fileEntry);
  //    }
  //  }
  //}

  //public fileOver(event)
  //{
  //  console.log(event);
  //}

  //public fileLeave(event)
  //{
  //  console.log(event);
  //}
}
