import { Component, Inject, EventEmitter, Output, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login, State, ResetPassword } from './../../datamodels.d';
import { babel } from '../../globals';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent
{
  @Output() stateChanged = new EventEmitter<State>();
  @Input() isLocal: boolean = false;
  @Input() isInit: boolean = false;

  public login: Login;
  public stateLogin: State;

  resetPasswordModel: ResetPassword = {
    newPassword: '',
    retypedPassword: '',
    code: '',
    userName: ''
  };

  mode: string = 'login';

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  code: string = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
    this.baseUrl = baseUrl;
    this.http = http;

    this.login = {
      username: '',
      password: ''
    };
  };

  ngOnInit()
  {
    this.route.queryParams.subscribe(params =>
    {
      this.code = params['code'];
      if (this.code !== undefined && this.code !== null)
      {
        this.toResetPassword();
      }
    });
  };

  toRequestResetPassword()
  {
    this.mode = 'requestresetpassword';
  };

  doRequestResetPassord()
  {
    this.http
      .post<State>(this.baseUrl + 'api/security/requestresetpassword', this.login)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Request for resetting password failed. Please check username.', 'Close');
          }
          else
          {
            this.login.username = '';
            this.login.password = '';
            this.toRequestResetPasswordDone();
          }
        },
        error => console.error(error));
  };

  toRequestResetPasswordDone()
  {
    this.mode = 'requestresetpassworddone';
  };

  toResetPassword()
  {
    this.mode = 'resetpassword';
  };

  doResetPassword()
  {
    if (this.resetPasswordModel.newPassword !== this.resetPasswordModel.retypedPassword)
    {
      this.snackBarRef = this.snackBar.open('Reset password failed. Password and retyped password are different.', 'Close');
      return;
    }

    if (this.resetPasswordModel.newPassword === null || this.resetPasswordModel.newPassword === '')
    {
      this.snackBarRef = this.snackBar.open('The new password is empty.', 'Close');
      return;
    }

    if (this.resetPasswordModel.userName === null || this.resetPasswordModel.userName === '')
    {
      this.snackBarRef = this.snackBar.open('The username is empty.', 'Close');
      return;
    }

    this.resetPasswordModel.code = this.code;

    this.http
      .post<State>(this.baseUrl + 'api/security/resetpassword', this.resetPasswordModel)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Reset password failed. Please contact your administrator.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('New password set. Please login now!', 'Close');

            this.resetPasswordModel = {
              newPassword: '',
              retypedPassword: '',
              code: '',
              userName: ''
            };

            this.login.username = '';
            this.login.password = '';
            this.toLogin();
          }
        },
        error => console.error(error));
  };

  toLogin()
  {
    this.mode = 'login';
  };

  doLogin()
  {
    if (this.snackBarRef)
    {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }

    this.http
      .post<State>(this.baseUrl + 'api/security/login', this.login)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Login failed. Please check username and password.', 'Close');
            babel.technicalRoleNames = null;
          }
          else
          {
            babel.technicalRoleNames = result.technicalRoleNames;
            babel.user = this.login.username;
          }

          this.stateChanged.emit(result);
        },
        error => console.error(error));

    return false;
  };

  doInitUser()
  {
    this.http
      .post<State>(this.baseUrl + 'api/security/inituser', null)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Init user done.', 'Close');
          }

          this.stateChanged.emit(result);
        },
        error => console.error(error));
  };
}
