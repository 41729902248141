import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, Global } from './../../datamodels.d'
import { MatDialog, MatDialogRef } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { SecurityService } from '../security.service';
import { babel } from '../../globals';

@Component({
  selector: 'app-companies-summary',
  templateUrl: './companies-summary.component.html',
  styleUrls: ['./companies-summary.component.css']
})

export class CompaniesSummaryComponent
{
  dialogRef: MatDialogRef<HistoryComponent>;
  public companies: Company[];

  babel: Global = babel;

  displayedColumns: string[] = ['name', 'disabled', 'edit'];

  constructor(http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private dialog: MatDialog,
    private security: SecurityService)
  {
    this.security.checkForRolesByName('companies.summary');

    http.get<Company[]>(baseUrl + 'api/companies/all').subscribe(result =>
    {
      this.companies = result;
    }, error => console.error(error));
  };

  openHistory(_companyID: number)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.companyID = _companyID;
  };
}
