import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { State, Global, RoleAccessObjects } from './../datamodels.d';
import { babel } from '../globals';
import { HttpErrorService } from './httperror.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent 
{
  dialogGeneric: MatDialogRef<DialogComponent>;
  http401Handled: boolean = false;

  public showLogin: boolean = true;
  public isLocal: boolean = false;
  public isInit: boolean = false;
  public isSessionLost: boolean = false;

  babel: Global = babel;

  title = 'app';
  state: State;

  constructor(private http: HttpClient,
    private httpErrorService: HttpErrorService,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog)
  {
    this.getRoleAccessObjects();
    this.doStateCheck();
  };

  ngOnInit()
  {
    this.httpErrorService.http401.asObservable().subscribe(values =>
    {
      if (values === true)
      {
        if (this.http401Handled === false)
        {
          this.isSessionLost = true;
          this.http401Handled = true;
          this.dialogGeneric = this.dialog.open(DialogComponent, {
            autoFocus: true,
            height: '250px',
            width: '550px'
          });
          this.dialogGeneric.componentInstance.options.actionYes = true;
          this.dialogGeneric.componentInstance.options.actionNo = true;
          this.dialogGeneric.componentInstance.options.title = 'Warning';
          this.dialogGeneric.componentInstance.options.message = 'Your session expired. You need to sign in to continue working. Do you want to be redirected to the login page?';
          this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
          {
            this.http401Handled = false;
            if (_action.action === 'no')
            {

            }
            else
            {
              this.http
                .get<State>(this.baseUrl + 'api/security/logout')
                .subscribe(result =>
                {
                  this.onStateChangedHandler(result);
                  babel.technicalRoleNames = null;
                }, error => console.error(error));
            }
          });
        }
      }
    });
  }

  getRoleAccessObjects()
  {
    this.http
      .get<RoleAccessObjects[]>(this.baseUrl + 'api/settings/roleaccessobjects')
      .subscribe(result =>
      {
        this.babel.setRoleAccessObjects(result);
      }, error => console.error(error));
  };

  doStateCheck()
  {
    this.http
      .get<State>(this.baseUrl + 'api/security/state')
      .subscribe(result =>
      {
        this.babel.version = result.version;
        this.state = result;
        this.isLocal = this.state.isLocal === true;
        this.isInit = this.state.isInit === true;
      }, error => console.error(error));
  };

  onStateChangedHandler(event: State)
  {
    this.state = event;
    this.showLogin =
      ((this.state.action === 1 || this.state.action === 4) &&
        this.state.value === true) === false;
    this.isSessionLost = this.showLogin;
  };
}
