import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { ActivatedRoute } from '@angular/router';
import { JobExtended, TextExtended } from '../../datamodels';

@Component({
  selector: 'app-translation-job-screens',
  templateUrl: './translation-job-screens.component.html',
  styleUrls: ['./translation-job-screens.component.css']
})

export class TranslationJobScreensComponent 
{
  dialogRef: MatDialogRef<HistoryComponent>;

  public mode: number = 0;

  public translationJobID: number = null;
  public textID: number = null;
  public textTypeID: number = null;
  public textIDindex: number = null;

  public selectedText: TextExtended = null;
  public textInfoVisible: boolean = true;

  public texts: TextExtended[];
  public screensByText: Screen[] = [];
  public screens: Screen[] = [];
  public screensToShow: Screen[] = [];
  public job: JobExtended;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private route: ActivatedRoute)
  {
  };

  ngOnInit()
  {
    this.translationJobID = parseInt(this.route.snapshot.paramMap.get('idjob'));
    this.getDetails();
    this.getScreens();
    this.getTextsOfJob();
  };

  isTextMode()
  {
    return this.mode === 1;
  };

  isTotalMode()
  {
    return this.mode === 0;
  };

  onTextChanged()
  {
    if (this.isTextMode() === false)
    {
      return;
    }

    this.getScreens();
  };

  getDetails()
  {
    this.http.get<JobExtended>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/details').subscribe(result =>
    {
      this.job = result;
    }, error => console.error(error));
  };

  getScreens()
  {
    this.screensToShow = [];

    this.http.get<Screen[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/screens').subscribe(result =>
    {
      this.screens = result;
      if (this.isTotalMode())
      {
        this.screensToShow = this.screens;
      }
    }, error => console.error(error));

    if (this.isTextMode())
    {
      this.http.get<Screen[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/text/' + this.textID + '/screens').subscribe(result =>
      {
        this.screensByText = result;
        this.screensToShow = this.screensByText;
      }, error => console.error(error));
    }
  };

  getTextsOfJob()
  {
    if (this.isTextMode() === false)
    {
      return;
    }

    this.http.get<TextExtended[]>(this.baseUrl + 'api/texts/oftranslationjob/' + this.translationJobID).subscribe(result =>
    {
      this.texts = result;

      if (this.texts !== null && this.texts.length > 0)
      {
        this.textIDindex = 0;
        this.textID = this.texts[0].id;
        this.textTypeID = this.texts[0].textTypeID;
        this.selectedText = this.texts[0];
        this.getScreens();
      }

    }, error => console.error(error));
  };

  nextText()
  {
    if (this.textIDindex === this.texts.length - 1)
    {
      return;
    }

    this.textIDindex++;
    this.selectedText = this.texts[this.textIDindex];
    this.textID = this.texts[this.textIDindex].id;
    this.textTypeID = this.texts[this.textIDindex].textTypeID;
    this.getScreens();
  };

  previousText()
  {
    if (this.textIDindex === 0)
    {
      return;
    }

    this.textIDindex--;
    this.selectedText = this.texts[this.textIDindex];
    this.textID = this.texts[this.textIDindex].id;
    this.textTypeID = this.texts[this.textIDindex].textTypeID;
    this.getScreens();
  };

  changeMode()
  {
    if (this.mode === 0)
    {
      this.mode = 1;
    }
    else if (this.mode === 1)
    {
      this.mode = 0;
    }

    this.getScreens();
    this.getTextsOfJob();
  };

  onTextSelected(_screensHaveTextsID: number)
  {
    for (var i = 0; i < this.texts.length; i++)
    {
      if (this.texts[i].textTypeID === _screensHaveTextsID)
      {
        this.textIDindex = i;
        this.textID = this.texts[i].id;
        this.textTypeID = this.texts[i].textTypeID;
        this.selectedText = this.texts[i];
        this.getScreens();
        break;
      }
    }
  };

  openHistory(_screenID)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.screenID = _screenID;
  };
}
