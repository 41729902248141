import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Screen, Language, JobExtended, CommentExtended, Status, TextHistoryFilter, User, TextExtended } from './../../datamodels.d'
import { TextHistoryFilterModel } from '../../texthistoryfiltermodel';
import { SecurityService } from '../security.service';

@Component({
  selector: 'app-text-history',
  templateUrl: './text-history.component.html',
  styleUrls: ['./text-history.component.css']
})

export class TextHistoryComponent 
{
  public filter: TextHistoryFilter = new TextHistoryFilterModel();

  public textID: number = null;
  public translationJobID: number = null;

  public languages: Language[];
  public translationjobs: JobExtended[];
  public users: User[];
  public historyItems: TextExtended[];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private security: SecurityService)
  {
    this.security.checkForRolesByName('history.text');
    this.getData();
  };

  ngOnInit()
  {
    this.getHistory();
  };

  getHistory()
  {
    this.http.get<TextExtended[]>(this.baseUrl + 'api/texts/history/' + this.textID + '/translationjob/' + this.translationJobID).subscribe(result =>
    {
      this.historyItems = result;
    }, error => console.error(error));
  };

  getData()
  {
    //this.http.get<Language[]>(this.baseUrl + 'api/languages/all').subscribe(result =>
    //{
    //  this.languages = result;
    //}, error => console.error(error));

    //this.http.get<JobExtended[]>(this.baseUrl + 'api/texts/translationjobs').subscribe(result =>
    //{
    //  this.translationjobs = result;
    //}, error => console.error(error));

    //this.http.get<User[]>(this.baseUrl + 'api/users/all').subscribe(result =>
    //{
    //  this.users = result;
    //}, error => console.error(error));
  };
}
