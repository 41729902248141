import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { Device, ReturnState, DeviceFilter, Task, Job, Global, State, Status, InExPort } from '../../datamodels';
import { DeviceNewEditComponent } from '../device-newedit/device-newedit.component';
import { TranslationTaskNewEditComponent } from '../translation-task-newedit/translation-task-newedit.component';
import { LanguagesAssignmentComponent } from '../languages-assignment/languages-assignment.component';
import { UploadEvent, FileSystemFileEntry, FileSystemDirectoryEntry, UploadFile } from 'ngx-file-drop';
import { Router } from '@angular/router';
import { babel } from '../../globals';
import { SecurityService } from '../security.service';
import { TaskModel } from '../../taskmodel';
import { JobExtendedModel } from '../../jobextendedmodel';
import { DeviceModel } from '../../devicemodel';
import { DialogComponent } from '../dialog/dialog.component';
import { FileListComponent } from '../file-list/file-list.component';

@Component({
  selector: 'app-devices-summary',
  templateUrl: './devices-summary.component.html',
  styleUrls: ['./devices-summary.component.css']
})

export class DevicesSummaryComponent
{
  public files: UploadFile[] = [];

  babel: Global = babel;

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  dialogRef: MatDialogRef<HistoryComponent>;
  dialogRefDevice: MatDialogRef<DeviceNewEditComponent>;
  dialogRefTask: MatDialogRef<TranslationTaskNewEditComponent>;
  dialogRefLang: MatDialogRef<LanguagesAssignmentComponent>;
  dialogGeneric: MatDialogRef<DialogComponent>;
  dialogFilesRef: MatDialogRef<FileListComponent>;

  public filter: DeviceFilter = {
    filterValue: null,
    archived: false,
    deviceID: null,
    taskID: null,
    jobID: null,
    stateID: null
  };

  public devices: Device[];

  public filterDevices: Device[];
  public filterTasks: Task[] = [];
  public filterJobs: Job[] = [];
  public filterStates: Status[];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService)
  {
    this.security.checkForRolesByName('devices.summary');

    this.loadDevices();
    this.getStates();
  };

  removeScreensOfTask(_translationTaskID: number)
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = 'Warning';
    this.dialogGeneric.componentInstance.options.message = 'Do you want to delete the xml-file of this translationtask?';
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }
      this.http
        .delete<ReturnState>(this.baseUrl + 'api/translationtasks/' + _translationTaskID + '/meta', {})
        .subscribe(
          _result =>
          {
            var result = _result as ReturnState;
            if (result.success)
            {
              this.loadDevices();
              this.snackBarRef = this.snackBar.open('Screens dropped.', 'Close');
            }
            else if (result.stateID === 1) // An INI-file has been already imported.
            {
              this.snackBarRef = this.snackBar.open('There are still languages in this task. Please delete them first.', 'Close');
            }
            else if (result.stateID === 2) // Invalid job-state
            {
              this.snackBarRef = this.snackBar.open('Not all jobs are still in DRAFT_NEW-state.', 'Close');
            }
            else if (result.stateID === 4) // Invalid job-state
            {
              this.snackBarRef = this.snackBar.open('There are still jobs in this task. Please delete them first.', 'Close');
            }
          },
          error => console.error(error));
    });
  };

  removeJob(_translationJobID: number)
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = 'Warning';
    this.dialogGeneric.componentInstance.options.message = 'Do you want to delete this translationjob?';
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }

      this.http
        .post<ReturnState>(this.baseUrl + 'api/translationjobs/' + _translationJobID + '/drop', {})
        .subscribe(
          result =>
          {
            if (result.success)
            {
              this.snackBarRef = this.snackBar.open('Translation job has been dropped.', 'Close');
              this.loadDevices();
            }
            else
            {
              if (result.stateID == 99)
              {
                this.snackBarRef = this.snackBar.open('Translationjob cannot be dropped since dropping is invalid in this state.', 'Close');
              }
              else
              {
                this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
                this.loadDevices();
              }
            }
          },
          error => console.error(error));

    });
  };

  loadDevices()
  {
    var urlFilter = this.filter.archived === true ? 'allalsoarchived' : 'all';
    var value = this.filter.filterValue == null ? ' ' : this.filter.filterValue;
    this.http.get<Device[]>(this.baseUrl + 'api/devices/' + urlFilter + '/' + value + '/' + this.filter.deviceID + '/' + this.filter.taskID + '/' + this.filter.jobID + '/' + this.filter.stateID).subscribe(result =>
    {
      this.devices = result;

      var emptyDevice = new DeviceModel();
      emptyDevice.id = null;
      emptyDevice.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyDevice);
      tmp.push.apply(tmp, this.devices);
      this.filterDevices = tmp;

      var emptyTask = new TaskModel();
      emptyTask.id = null;
      emptyTask.name = '<no selection>';
      var tmpTasks = [];
      tmpTasks.push(emptyTask);

      var emptyJob = new JobExtendedModel();
      emptyJob.id = null;
      emptyJob.name = '<no selection>';
      var tmpJobs = [];
      tmpJobs.push(emptyJob);

      for (var d = 0; d < this.devices.length; d++)
      {
        for (var tt = 0; tt < this.devices[d].translationTasks.length; tt++)
        {
          tmpTasks.push(this.devices[d].translationTasks[tt]);

          for (var tj = 0; tj < this.devices[d].translationTasks[tt].translationJobs.length; tj++)
          {
            tmpJobs.push(this.devices[d].translationTasks[tt].translationJobs[tj]);
          }
        }
      }

      this.filterJobs = tmpJobs;
      this.filterTasks = tmpTasks;

    }, error => console.error(error));
  };

  clearFilter()
  {
    this.filter.filterValue = null;
    this.filter.archived = false;
    this.filter.deviceID = null;
    this.filter.jobID = null;
    this.filter.stateID = null;
    this.filter.taskID = null;
    this.loadDevices();
  };

  getStates()
  {
    this.http.get<Status[]>(this.baseUrl + 'api/status/translationjob').subscribe(result =>
    {
      var emptyState = {
        value: null,
        name: '<no selection>'
      };

      var tmp = [];
      tmp.push(emptyState);
      tmp.push.apply(tmp, result);
      this.filterStates = tmp;
    }, error => console.error(error));
  };

  openFiles(_task, _job)
  {
    this.dialogFilesRef = this.dialog.open(FileListComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogFilesRef.componentInstance.onClose.subscribe(() =>
    {
      this.dialogFilesRef.close();
      this.dialogFilesRef = null;
    });
    this.dialogFilesRef.componentInstance.hasPackageDownloadOfSourceFiles = true;

    if (_job != null)
    {
      this.dialogFilesRef.componentInstance.itemName = _job.name;
      this.dialogFilesRef.componentInstance.translationJobID = _job.id;
    }
    else if (_task != null)
    {
      this.dialogFilesRef.componentInstance.itemName = _task.name;
      this.dialogFilesRef.componentInstance.translationTaskID = _task.id;
      this.dialogFilesRef.componentInstance.hasPackageExportOfSdlxliffFiles = true;
    }
  };

  openHistory(_deviceID: number, _taskID: number, _jobID: number)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.deviceID = _deviceID;
    this.dialogRef.componentInstance.filter.translationTaskID = _taskID;
    this.dialogRef.componentInstance.filter.translationJobID = _jobID;
  };

  showDeviceDialog(_deviceID: number)
  {
    this.dialogRefDevice = this.dialog.open(DeviceNewEditComponent, {
      autoFocus: true,
      height: '400px',
      width: '450px'
    });
    this.dialogRefDevice.componentInstance.deviceID = _deviceID;
    this.dialogRefDevice.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefDevice.close();
      this.loadDevices();
    });
    this.dialogRefDevice.afterClosed().subscribe(() => { });
  };

  showTaskDialog(_deviceID: number, _taskID: number, _device: Device)
  {
    this.dialogRefTask = this.dialog.open(TranslationTaskNewEditComponent, {
      autoFocus: true,
      height: '400px',
      width: '450px'
    });
    this.dialogRefTask.componentInstance.deviceID = _deviceID;
    this.dialogRefTask.componentInstance.translationTaskID = _taskID;
    this.dialogRefTask.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefTask.close();
      this.loadDevices();
    });
    this.dialogRefTask.afterClosed().subscribe(() => { });
  };

  showJobAddDialog(_device: Device, _task: Task)
  {
    if (_task.screensImported === false || _task.defaultTextsImported === false)
    {
      this.snackBarRef = this.snackBar.open('Please ensure that XML- as well as INI-files have been imported first before adding jobs.', 'Close');
      return;
    }

    this.router.navigate(['/devices', _device.id, 'tasks', _task.id, 'newjobs']);
  };

  showLanguageSelectionDialog(_device: Device, _task: Task) 
  {
    this.dialogRefLang = this.dialog.open(LanguagesAssignmentComponent, {
      autoFocus: true,
      height: '80%',
      width: '85%'
    });
    this.dialogRefLang.componentInstance.deviceName = _device.name;
    this.dialogRefLang.componentInstance.deviceID = _device.id;
    this.dialogRefLang.componentInstance.taskName = _task.name;
    this.dialogRefLang.componentInstance.taskID = _task.id;
    this.dialogRefLang.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefLang.close();
      this.loadDevices();
    });
    this.dialogRefLang.componentInstance.onUpdated.subscribe(() =>
    {
      this.loadDevices();
    });
    this.dialogRefLang.afterClosed().subscribe(() => { });
  };

  public dropped(_event: UploadEvent, _translationTask: Task)
  {
    this.files = _event.files;
    this.http.get<ReturnState>(this.baseUrl + 'api/translationtasks/' + _translationTask.id + '/files/').subscribe(result =>
    {
      var existingFiles = result.data as InExPort[];

      for (const droppedFile of _event.files)
      {
        // Is it a file?
        if (droppedFile.fileEntry.isFile)
        {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) =>
          {
            var fileExists = false,
              languageID = null,
              fileName = '';
            for (var f = 0; f < existingFiles.length; f++)
            {
              if ((existingFiles[f].importExport === 16 && droppedFile.fileEntry.name.endsWith('.xml')) ||
                (existingFiles[f].importExport === 8 && droppedFile.fileEntry.name.endsWith('.ini') && existingFiles[f].name.toLowerCase() === droppedFile.relativePath.toLowerCase()))
              {
                fileExists = true;
                languageID = existingFiles[f].languageID;
                fileName = existingFiles[f].name;
                break;
              }
            }

            if (fileExists === true)
            {
              this.dialogGeneric = this.dialog.open(DialogComponent, {
                autoFocus: true,
                height: '250px',
                width: '550px'
              });
              this.dialogGeneric.componentInstance.options.actionYes = true;
              this.dialogGeneric.componentInstance.options.actionCancel = true;
              this.dialogGeneric.componentInstance.options.title = 'Warning';
              this.dialogGeneric.componentInstance.options.message = 'The file [' + fileName + '] already exists. Do you want to replace it?';
              this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
              {
                if (_action.action !== 'yes')
                {
                  return;
                }

                this.uploadFile(file, droppedFile, _translationTask, true, languageID);
              });
            }
            else
            {
              this.uploadFile(file, droppedFile, _translationTask, false, null);
            }

          });
        }
        else
        {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
        }
      }

    }, error => console.error(error));
  };

  uploadFile(_file: File, _droppedFile: UploadFile, _translationTask: Task, _replace: boolean, _languageID?: number)
  {
    var replaceHandling = _replace ? 'replace' : 'noreplace';

    const formData = new FormData();
    formData.append('_file', _file, _droppedFile.relativePath);
    this.http.post(this.baseUrl + 'api/translationtasks/' + _translationTask.id + '/meta/' + replaceHandling + '/' + _languageID, formData)
      .subscribe(data =>
      {
        var d = data as ReturnState;
        if (d.success)
        {
          this.snackBarRef = this.snackBar.open('File [' + _droppedFile.relativePath + '] has been successfully imported.', 'Close');
          this.loadDevices();
        }
        else
        {
          if (d.stateID === 1) //Unknown fileformat
          {
            this.snackBarRef = this.snackBar.open('This is an unknown file format.', 'Close');
          }
          else if (d.stateID === 2) // XML
          {
            this.snackBarRef = this.snackBar.open('Cannot replace language. Technical error.', 'Close');
          }
          else if (d.stateID === 3) // XML
          {
            this.snackBarRef = this.snackBar.open('Replace of screens-XML not possible. An ini-file has already been imported.', 'Close');
          }
          else if (d.stateID === 4) // XML
          {
            this.snackBarRef = this.snackBar.open('Replace of screens-XML not possible. At least one job is not in state DRAFT_NEW.', 'Close');
          }
          else if (d.stateID === 5) // XML
          {
            this.snackBarRef = this.snackBar.open('Replace of screens-XML not possible. There are still jobs', 'Close');
          }
          else if (d.stateID === 6) // XML
          {
            this.snackBarRef = this.snackBar.open('Replace of ini-file not possible. The state of jobs is not valid.', 'Close');
          }
          else if (d.stateID === 8) // XML
          {
            this.snackBarRef = this.snackBar.open('There has been already an XML-file imported for this translationtask.', 'Close');
          }
          else if (d.stateID === 9) // INI
          {
            this.snackBarRef = this.snackBar.open('There has been already an INI-file imported for this translationtask.', 'Close');
          }
          else if (d.stateID === 10) // XML missing for INI
          {
            this.snackBarRef = this.snackBar.open('The XML-file has not yet been imported. Please import before importing INI-file.', 'Close');
          }
          else if (d.stateID === 12) // Language
          {
            this.snackBarRef = this.snackBar.open('The default-language could not be found. Has the XML-file already been imported?', 'Close');
          }
          else if (d.stateID === 13) // Invalid job-state
          {
            this.snackBarRef = this.snackBar.open('The file contains errors.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('An unknown error occured!', 'Close');
          }
        }
      });
  };

  public fileOver(event)
  {
    console.log(event);
  };

  public fileLeave(event)
  {
    console.log(event);
  };

  toggleExpandCollapse(_device: Device, _task: Task)
  {
    var type = '';
    var id = 0;
    var state = '';

    if (_device)
    {
      _device.expanded = !_device.expanded;
      type = 'device';
      id = _device.id;
      state = _device.expanded ? 'expanded' : 'collapsed';
    }

    if (_task)
    {
      _task.expanded = !_task.expanded;
      type = 'translationtask';
      id = _task.id;
      state = _task.expanded ? 'expanded' : 'collapsed';
    }

    this.http
      .post<ReturnState>(this.baseUrl + 'api/settings/' + type + '/' + state + '/' + id, null)
      .subscribe(
        result =>
        {
        },
        error => console.error(error));
  };
}
