import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, ReturnState, Device, InExPort } from './../../datamodels.d'
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})

export class FileListComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  displayedColumns: string[] = ['dateTime', 'type', 'name', 'download'];

  public onClose = new EventEmitter();

  translationJobID: number = 0;
  translationTaskID: number = 0;
  itemName: string = null;

  hasPackageDownloadOfSourceFiles: boolean = false;
  hasPackageExportOfSdlxliffFiles: boolean = false;

  files: InExPort[] = [];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    if ((this.translationJobID === 0 || this.translationJobID === null) &&
      (this.translationTaskID === 0 || this.translationTaskID === null))
    {
      return;
    }

    this.getFiles();
  };

  download(_element: InExPort)
  {
    const url = this.baseUrl + 'api/inexports/file/' + _element.id;
    if (url !== null)
    {
      window.open(url);
    }
  };

  getFiles()
  {
    if (this.translationJobID > 0)
    {
      this.http.get<ReturnState>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/files').subscribe(result =>
      {
        this.files = result.data as InExPort[];
      }, error => console.error(error));
    }
    else if (this.translationTaskID > 0)
    {
      this.http.get<ReturnState>(this.baseUrl + 'api/translationtasks/' + this.translationTaskID + '/files').subscribe(result =>
      {
        this.files = result.data as InExPort[];
      }, error => console.error(error));
    }
  };

  downloadSourceFiles()
  {
    const url =
      this.translationJobID > 0
        ? this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/initialfiles'
        : this.translationTaskID > 0
          ? this.baseUrl + 'api/translationtasks/' + this.translationTaskID + '/initialfiles'
          : null;

    if (url !== null)
    {
      window.open(url);
    }
  };

  downloadSdlxliffExport()
  {
    const url =
      this.translationTaskID > 0
        ? this.baseUrl + 'api/texts/translationtask/' + this.translationTaskID + '/xliff'
        : null;

    if (url !== null)
    {
      window.open(url);
    }
  };

  close()
  {
    this.onClose.emit();
  };
}
