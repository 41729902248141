import { Injectable } from '@angular/core';
import { babel } from '../globals';
import { Global, RoleAccessObjects } from '../datamodels';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class SecurityService
{
  babel: Global = babel;

  constructor(private router: Router) { }

  checkForRolesByName(_roleAccessObjectName: string, _redirect: boolean = true)
  {
    var rao: RoleAccessObjects = null;
    for (var i = 0; i < this.babel.roleAccessObjects.length; i++)
    {
      if (this.babel.roleAccessObjects[i].name === _roleAccessObjectName)
      {
        rao = this.babel.roleAccessObjects[i];
        break;
      }
    }

    if (rao === null)
    {
      this.checkForRoles([], _redirect);
      return;
    }

    var roles = [];
    if (rao.roleAdministrator)
    {
      roles.push('A');
    }

    if (rao.roleDeveloper)
    {
      roles.push('D');
    }

    if (rao.roleTechnicalWriter)
    {
      roles.push('TC');
    }

    if (rao.roleTranslator)
    {
      roles.push('T');
    }

    return this.checkForRoles(roles, _redirect);
  };

  checkForRoles(_roles: string[], _redirect: boolean = true)
  {
    var success = false;
    for (var i = 0; i < _roles.length; i++)
    {
      if (babel.hasRole(_roles[i]))
      {
        success = true;
        break;
      }
    }

    if (!success && _redirect === true)
    {
      this.router.navigate(['']);
    }

    return success;
  };
}
