import { Device, Task } from './datamodels';

export class DeviceModel implements Device
{
  id: number;
  name: string;
  translationTasks: Task[];
  expanded: boolean;
  archived: boolean;
}
